import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"

import { media } from "../../styles/media"
import SvgLogo from "../../images/francoisLogoENwhite2.svg"

import LangLayout from "../../components/LangLayout"
import SEO from "../../components/SEO"
import { useLocationData } from "../../hooks/locationHook"

const StyledTop = styled.div`
  width: 100%;
  height: 40vh;
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
  .logoImage {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0%;
    width: 40%;
    opacity: 0.4;
  }
  ${() =>
    media.sp(css`
      height: 30vh;
      .logoImage {
        width: 80%;
      }
    `)}
`

const StyledCards = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 10vh 5%;
  padding: 5vh 0;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  .menuCard {
    width: 24.5%;
    margin: 0 1px;
    position: relative;
    height: 250px;
    overflow: hidden;
    &:after {
      background: black;
      opacity: 0.2;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transition: 0.3s;
    }
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.6s;
    }
    a {
      font-size: 1.32em;
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 0.62em;
      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 5px;
        margin-top: 3px;
        background: ${({ theme }) => theme.colors.primary.red};
        transition: 0.3s;
        opacity: 0;
        transform: translateY(5px);
      }
    }
    &:hover {
      &:after {
        opacity: 0.4;
      }
      .gatsby-image-wrapper {
        transform: scale(1.1, 1.1);
      }
      a:after {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
  ${() =>
    media.tablet(css`
      margin: 5vh 10%;
      padding: 3vh 0;
    `)}
  ${() =>
    media.sp(css`
      margin: 0vh 0;
      padding: 1vh 0;
      justify-content: space-between;
      .menuCard {
        width: 49%;
        margin-bottom: 1vh;
        height: 200px;
      }
    `)}
`

const TopPageCn = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)

  return (
    <LangLayout>
      <SEO>
        <title>中国业务｜法兰索亚</title>
        <meta
          name="description"
          content="让满意的笑容在世界各地绽放。法兰索亚的美味飘洋过海,来到您的身边。
（法兰索亚(青岛)食品有限公司Francois／开封香麦士食品有限公司 JEANAVICE／香奈维斯(天津)食品有限公司JEANAVICE）"
        />
      </SEO>

      <StyledTop>
        <Image
          fluid={data.zoo.topMedia.childImageSharp.fluid}
          alt={data.zoo.topMediaAlt}
        />
        <img className="logoImage" src={SvgLogo} alt="francoisLogo" />
      </StyledTop>
      <StyledCards>
        <div className="menuCard">
          <Link to={data.zoo.langTopList.philoUrl}>
            {data.zoo.langTopList.cnVision}{" "}
          </Link>
          <Image
            fluid={data.zoo.langTopList.philoMedia.childImageSharp.fluid}
            alt={data.zoo.langTopList.philoMediaAlt}
          />
        </div>
        <div className="menuCard">
          <Link to={data.zoo.langTopList.visionUrl}>
            {data.zoo.langTopList.enCommit}{" "}
          </Link>
          <Image
            fluid={data.zoo.langTopList.visionMedia.childImageSharp.fluid}
            alt={data.zoo.langTopList.visionMediaAlt}
          />
        </div>
        <div className="menuCard">
          <Link to={data.zoo.langTopList.productBrandUrl}>
            {data.zoo.langTopList.enProduct}{" "}
          </Link>
          <Image
            fluid={data.zoo.langTopList.productMedia.childImageSharp.fluid}
            alt={data.zoo.langTopList.productMediaAlt}
          />
        </div>
        <div className="menuCard">
          <Link to={data.zoo.langTopList.companyUrl}>
            {data.zoo.langTopList.cnInfo}{" "}
          </Link>
          <Image
            fluid={data.zoo.langTopList.companyMedia.childImageSharp.fluid}
            alt={data.zoo.langTopList.companyMediaAlt}
          />
        </div>
      </StyledCards>
    </LangLayout>
  )
}

export const query = graphql`
  {
    zoo: strapiLangTop {
      topMediaAlt
      topMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      langTopList: langTopListCn {
        cnVision
        enCommit
        cnInfo
        enProduct
        companyMediaAlt
        companyUrl
        companyMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        philoMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        philoMediaAlt
        philoUrl
        productBrandUrl
        productMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        productMediaAlt
        visionMediaAlt
        visionUrl
        visionMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default TopPageCn
